import React, { Component } from 'react';
import FormHeader from "./FormHeader"
import FormStep1 from "./FormStep1RateAssistance"
import FormStep2Feedback from "./FormStep2Feedback"
import FormStep3Resolution from "./FormStep3Resolution"
import FormStep4Agent from "./FormStep4Agent"
import FormStep5Improvement from "./FormStep5Improvement"
import FormThankYou from "./FormThankYou";
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';



class Form extends Component {
    state = {
        step: 0,
        toggle: "",
        detailFeedback: null,
        resolution: null,
        resolutionDetails: "",
        agent: null,
        agentDetails: "",
        improvementsDetails: "",
        ticketID: null,
        agentName: ""

    }
    componentDidMount() {
        let token = localStorage.getItem('authToken');
        const pathName = window.location.pathname;
        if(pathName.length < 2){
            this.setState({step: 6});
        }
        let uuid = pathName.slice(1);
        let dataToSend = {
            uuid: uuid
        }
        if (!token) {
            const apiUrl = `https://support-survey-server.nordcurrent.com/get`;
             axios.post(apiUrl, dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    token = response.data;
                    localStorage.setItem('authToken', token)
                    this.setStateByToken(token);
                })
                .catch(error => {
                    this.setState({step:6});
                 console.log(error)
                });


        }else {
            this.setStateByToken(token);
        }
    }

    async fetchData () {
        try {
            let dataToSend = {
                ticket_id: this.state.ticketID,
                rating: this.state.toggle,
                resolution: this.state.resolution,
                resolutionComment: this.state.resolutionDetails,
                communication: this.state.agent,
                communicationComment: this.state.agentDetails,
                improvementComment: this.state.improvementsDetails
            }
            let token = localStorage.getItem('authToken');
            if(!token){
                throw new Error('Missing token');
            }
            axios.post(`https://support-survey-server.nordcurrent.com/submit`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
                .then(response => { 
                })
                .catch(error => {
                    console.log(error)
                });
        } catch (error) {
            console.error('Error:', error);
        }
    }

    setStateByToken = (token) => {
        let decodedToken = jwtDecode(token, false);
        this.setState({
            step: 1,
            ticketID: decodedToken.ticket_id,
            agentName: decodedToken.agent
        });
    }

    nextStep = () => {
        const { step } = this.state
        this.setState({
            step: step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step: step - 1
        })
    }

    handleEmojiButtonToggle = (button) => {
        this.setState({toggle:button});
    }

    handleFormButtonToggle = (key, value) => {
        this.setState({
            [key]: value
        });
    }

    handleFormTextChange = (key, value) => {
        if(value.length < 2000) {
            this.setState({
                [key]: value
            });
        }
    }

     onSubmit = async () => {
        await this.fetchData();
        this.setState({
            step: 6
        })
        localStorage.removeItem('authToken');
    }


    render() {
        const screenDimensions = window.screen.availWidth;
        const {step, toggle, detailFeedback, resolution, resolutionDetails, agent, agentDetails, ticketID, agentName, improvementsDetails} = this.state;
        const inputValues = {toggle, screenDimensions, detailFeedback, resolution, resolutionDetails, agent, agentDetails, ticketID, agentName, improvementsDetails}
        var body =  "";
        var header = "";

        switch (step) {
            case 0:
                header = <div></div>
                body = <div></div>
            break;
            case 1:
                header = <FormHeader/>
                body = <FormStep1
                    nextStep={this.nextStep}
                    handleButtonToggle={this.handleEmojiButtonToggle}
                    inputValues = {inputValues}
                />;
                break;
            case 2:
                header = <FormHeader
                    step = {step}
                />
                body = <FormStep2Feedback
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    onSubmit={this.onSubmit}
                    handleButtonToggle={this.handleFormButtonToggle}
                    inputValues = {inputValues}
                />;
                break;
            case 3:
                header = <FormHeader
                    step = {step}
                />
                body = <FormStep3Resolution
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleButtonToggle={this.handleFormButtonToggle}
                    handleTextChange = {this.handleFormTextChange}
                    inputValues = {inputValues}
                />;
                break;
            case 4:
                header = <FormHeader
                    step = {step}
                />
                body = <FormStep4Agent
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleButtonToggle={this.handleFormButtonToggle}
                    handleTextChange = {this.handleFormTextChange}
                    inputValues = {inputValues}
                />;
                break;
            case 5:
                header = <FormHeader
                    step = {step}
                />
                body = <FormStep5Improvement
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    onSubmit={this.onSubmit}
                    handleTextChange = {this.handleFormTextChange}
                    inputValues = {inputValues}
                />;
                break;
            case 6:
                header = null;
                body = <FormThankYou
                />;
                break;
        }
        return (
            <div className="container">
                {header}
                {body}
            </div>
        );

    }
}

export {Form};