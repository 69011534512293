import React, { Component } from 'react';
import {Container} from "react-bootstrap";
import groupImage from "../assets/Group-Image.png";
import groupImageSmall from "../assets/Characters2.png";
import { ReactComponent as Palm } from "../assets/Palm.svg";
import { ReactComponent as NCLogo } from "../assets/nordcurrent-logo.svg";



class FormThankYou extends Component {


    render() {
        //525px
        return (
            <Container className="Form-body form-thank-you Rate justify-content-end">
                <NCLogo className="form-thank-you-logo"/>
                <Palm className="intersect"/>
                <picture>
                    <source media="(max-width: 1000px)" srcSet={groupImageSmall}/>
                    <source media="(min-width: 1001px)" srcSet={groupImage}/>
                    <img className="group-image img-fluid" src={groupImage} alt="Responsive"/>
                </picture>
                <p className="form-thank-you-text">Thank you!</p>
                <p className="form-thank-you-text small">Feel free to contact us anytime!</p>
            </Container>
        );
    }
}

export default FormThankYou;